import React from 'react';
import value from '../../assets/images/value.png'
import { StaticQuery, graphql } from "gatsby"

const OurValue = () => (
    <section className="our-value about-ourvalue">
        <div className="value-img mobile-hidden">
            <img src={value}/>
        </div>
        <StaticQuery
         query={graphql`
            query {
                allWpPage(filter: {slug: {eq: "about-us"}}) {
                    edges {
                      node {
                        AboutUs {
                          ourValues {
                            description
                            featureList {
                              title
                              svgIcon
                              iconBgColour
                              iconColour
                            }
                            fieldGroupName
                            subHeadline
                            title
                          }
                        }
                      }
                    }
                  }
            }
         `}
         render={data => {
             return(
                data && 
                data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                data.allWpPage.edges[0].node.AboutUs &&
                data.allWpPage.edges[0].node.AboutUs.ourValues &&
                <div className="container ">
                  <div className="row d-flex align-items-center ">
                    <div className="col-md-6 about-heading mt-0 pl-0">
                        <p className="text-uppercase">{data.allWpPage.edges[0].node.AboutUs.ourValues.title}</p>
                        <h1 className="mb-3">{data.allWpPage.edges[0].node.AboutUs.ourValues.subHeadline}</h1>
                        <div dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.ourValues.description }}  className="why-disp mb-5"></div>
                    </div>
                    <div className="col-md-6">
                        <div className="row ">
                            { data.allWpPage.edges[0].node.AboutUs.ourValues.featureList&&
                              data.allWpPage.edges[0].node.AboutUs.ourValues.featureList.length>0&&
                              data.allWpPage.edges[0].node.AboutUs.ourValues.featureList.map((data,key)=>{
                                return(
                                    <div key={key} className="our-features col-sm-6 text-center">
                                        <figure style={{backgroundColor:data.iconBgColour}} dangerouslySetInnerHTML={{ __html: data.svgIcon }} className="bg-green featured-icons "></figure>
                                        <h6 className="m-0">{data.title}</h6>
                                    </div>
                                )
                              })
                            
                            }
                        </div>
                    </div>
                </div>
              </div>
             )
         }}
        />
        
    </section>
)

export default OurValue;