import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/about/banner"
import OurMission from "../components/about/mission";
import OurValue from "../components/about/ourvalue";
import Collaboration from "../components/about/collaboration";
import BuildBetter from "../components/about/buildbetter";
import Testimonial from "../components/home/testimonial";
import Count from "../components/about/count";
import ComeOut from "../components/about/comeout";

const AboutUs = (props) => (
     <Layout {...props}>
         <SEO title="About Us" />
         <div class="col-12 wrapper main-bggrey p-0">
             <div class="col-12 inner-page-content home-inner px-0">
                 <Banner/>
             </div> 
         </div>
         <OurMission/>
         <OurValue/>
         <Collaboration/>
         <BuildBetter/>
         <Count/>
         <ComeOut/>
         <Testimonial/>
     </Layout>
)

export default AboutUs;

