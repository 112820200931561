import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const OurMission = () => (
  <section className="mission-sec about-sec only-about">
    <div className="workflow-triangle">
       <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"></path>
       </svg>
    </div>
    <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "about-us"}}) {
                  edges {
                    node {
                      AboutUs {
                        ourMission {
                          description
                          mediaGallery {
                            description
                            sourceUrl
                            title
                          }
                          title
                          subHeadline
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
               data && 
               data.allWpPage &&
               data.allWpPage.edges &&
               data.allWpPage.edges[0] &&
               data.allWpPage.edges[0].node &&
               data.allWpPage.edges[0].node.AboutUs &&
               data.allWpPage.edges[0].node.AboutUs.ourMission &&
               <div className="container">
                     <div style={{position:"relative", zIndex:9}} className="row about-heading d-flex justify-content-between">
                        <div className="col-md-6 pl-0">
                           <p className="text-uppercase">{data.allWpPage.edges[0].node.AboutUs.ourMission.title}</p>
                           <h1>{data.allWpPage.edges[0].node.AboutUs.ourMission.subHeadline}</h1>
                        </div>
                        <div className="col-md-5 d-flex align-items-center flex-column">
                           <p className="w-100 m-hide">&nbsp;</p>
                           <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.ourMission.description }} className="about-disp mb-0 letter-spacing-none"></span>
                        </div>
                     </div>
                     <div className="row mission-gallery mt-4 d-flex">
                        {
                           data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery
                           && data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery.length>0&&
                           <React.Fragment>
                              <div className="col-md-4 p-0 pr-2 ">
                                 <img src={data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery[0].sourceUrl} />
                              </div>
                              <div className="col-md-4 p-0 mid-block">
                                 <div className="col-12 p-0 mb-2 ">
                                    <img src={data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery[1].sourceUrl} />
                                 </div>
                                 <div className="col-12 p-0">
                                    <img src={data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery[2].sourceUrl} />
                                 </div>
                              </div>
                              <div className="col-md-4 p-0 pl-2">
                                 <img src={data.allWpPage.edges[0].node.AboutUs.ourMission.mediaGallery[3].sourceUrl} />
                              </div>
                           </React.Fragment>
                        }
                     </div>
                 </div>
            ) 
         }}
      />
  </section>
)

export default OurMission;