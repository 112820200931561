import React from "react"
import solutionabout from "../../assets/images/solution-about.png";
//import rl from "../../assets/images/rl.png";
import { StaticQuery, graphql  } from 'gatsby';

const BuildBetter = () => (
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "about-us"}}) {
                  edges {
                    node {
                      AboutUs {
                        engineeringAtScale {
                           buttonBgColour
                           buttonLink
                           buttonText
                           buttonTextColour
                           description
                           description1
                           image {
                             title
                             sourceUrl
                           }
                           title
                         }
                         buildBetter {
                           title
                           subHeadline
                           featuresList {
                             title
                             svgIcon
                             iconColour
                             iconBgColour
                             description
                           }
                         }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
              data && data.allWpPage &&
              data.allWpPage.edges &&
              data.allWpPage.edges[0] &&
              data.allWpPage.edges[0].node &&
              data.allWpPage.edges[0].node.AboutUs &&
              data.allWpPage.edges[0].node.AboutUs.buildBetter &&
              <section id="solutions" className="my-0 solution-sec carrer-worksec">
               <div className="tm-bg-sol mobile-hidden">
                   <img alt="solutionabout" src={solutionabout}/> 
               </div>
               <div className="container">
                 <div style={{position:"relative", zIndex:9}} className="row about-heading d-flex justify-content-between">
                    <div className="col-md-6 pl-sm-4 ">
                       <p className="text-uppercase pl-sm-2">{data.allWpPage.edges[0].node.AboutUs.buildBetter.title}</p>
                       <h1 className="m-0 pl-sm-2">{data.allWpPage.edges[0].node.AboutUs.buildBetter.subHeadline}</h1>
                    </div>
                    <div className="col-md-5 d-flex align-items-center ">
                       <p className="about-disp mb-0 letter-spacing-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt amet, eget vel, sollicitudin. Metus non amet ut orci orci sapien, tellus. Viverra vitae ornare pellentesque at urna. Non in interdum ac elit.</p>
                    </div>
                 </div>
                 <div className="row mt-5 d-flex justify-content-between">
                    <div className="col-md-6">
                       {data.allWpPage.edges[0].node.AboutUs.buildBetter.featuresList
                        &&data.allWpPage.edges[0].node.AboutUs.buildBetter.featuresList.length>0
                        &&data.allWpPage.edges[0].node.AboutUs.buildBetter.featuresList.map((featureList,key)=>{
                           return (
                              <div className="solution-content d-flex m-hide">
                                 <div dangerouslySetInnerHTML={{ __html: featureList.svgIcon }} style={{backgroundColor:featureList.iconBgColour}} className="icon-s"></div>
                                 <div className="s-cnt">
                                    <h4 className="m-0">{featureList.title}</h4>
                                    <span dangerouslySetInnerHTML={{ __html: featureList.description }}></span>
                                 </div>
                              </div>
                           )
                        })
                       }
                      
                      
                       <div id="myCarouselabt" className="carousel slide textcarousel" data-ride="carousel">
                          <ol className="carousel-indicators d-flex">
                             <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                             <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                             <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                          </ol>
                          <div className="carousel-inner">
                             <div className="item carousel-item active">
                                <div className="solution-content d-flex">
                                   <div className="icon-s bg-green">
                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M3.75 17.5C3.75 17.0027 3.94754 16.5258 4.29917 16.1742C4.65081 15.8225 5.12772 15.625 5.625 15.625H16.25V2.5H5.625C5.12772 2.5 4.65081 2.69754 4.29917 3.04917C3.94754 3.40081 3.75 3.87772 3.75 4.375V17.5Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M3.75 17.5V18.125H15" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                   </div>
                                   <div className="s-cnt">
                                      <h4 className="m-0">Industry-leading documentation</h4>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet posuere lorem eget est. Id vitae a pellentesque in nunc non eleme ntum nibh tellus. Facilisis ut mattis morbi enim.</p>
                                   </div>
                                </div>
                             </div>
                             <div className="item carousel-item">
                                <div className="solution-content d-flex">
                                   <div className="icon-s f-3">
                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <g clip-path="url(#clip0)">
                                            <path d="M19.7955 9.48702L15.1704 4.58699C14.8862 4.28551 14.4117 4.27176 14.1102 4.55625C13.8092 4.84051 13.7952 5.31524 14.0797 5.61649L18.219 10.0015L14.0797 14.3871C13.7952 14.6883 13.8092 15.1628 14.1102 15.4473C14.2552 15.5841 14.4404 15.6518 14.625 15.6518C14.8242 15.6518 15.0232 15.5728 15.1705 15.417L19.7955 10.5168C20.0682 10.2275 20.0682 9.77601 19.7955 9.48702Z" fill="white"/>
                                            <path d="M5.92056 14.383L1.78155 9.99767L5.92056 5.61241C6.20482 5.31116 6.19107 4.83639 5.88982 4.55217C5.5888 4.26791 5.1138 4.28166 4.82958 4.58291L0.204552 9.48294C-0.068184 9.77193 -0.068184 10.2237 0.204552 10.5127L4.82981 15.413C4.97731 15.5692 5.17606 15.648 5.37532 15.648C5.55982 15.648 5.74509 15.58 5.88982 15.4432C6.19134 15.1589 6.20482 14.6842 5.92056 14.383Z" fill="white"/>
                                            <path d="M11.2134 2.05935C10.8041 1.99736 10.4211 2.27834 10.3586 2.6876L8.15859 17.0877C8.09609 17.4972 8.37735 17.88 8.78684 17.9425C8.82536 17.9482 8.86333 17.951 8.9011 17.951C9.26536 17.951 9.58485 17.6852 9.64161 17.3142L11.8416 2.91412C11.9041 2.50459 11.6229 2.12185 11.2134 2.05935Z" fill="white"/>
                                         </g>
                                         <defs>
                                            <clipPath id="clip0">
                                               <rect width="20" height="20" fill="white"/>
                                            </clipPath>
                                         </defs>
                                      </svg>
                                   </div>
                                   <div className="s-cnt">
                                      <h4  className="m-0">Support for the developer community</h4>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet posuere lorem eget est. Id vitae a pellentesque in nunc non eleme ntum nibh tellus. Facilisis ut mattis morbi enim.</p>
                                   </div>
                                </div>
                             </div>
                             <div className="item carousel-item">
                                <div className="solution-content d-flex ">
                                   <div className="icon-s bg-blue">
                                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M20.6867 10.467L18.5625 11.5291L15.8125 6.26267L17.9583 5.18977C18.1195 5.10916 18.3059 5.09509 18.4774 5.15058C18.6489 5.20607 18.7917 5.32669 18.8752 5.48647L20.9886 9.53386C21.0309 9.61479 21.0566 9.70333 21.0643 9.79431C21.072 9.88529 21.0614 9.97688 21.0333 10.0638C21.0052 10.1506 20.9601 10.231 20.9006 10.3002C20.841 10.3695 20.7683 10.4262 20.6867 10.467V10.467Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M3.4375 11.4353L1.31333 10.3733C1.23167 10.3324 1.15896 10.2757 1.09944 10.2065C1.03992 10.1373 0.994792 10.0569 0.966683 9.97C0.938573 9.88314 0.928048 9.79154 0.935721 9.70056C0.943393 9.60959 0.96911 9.52105 1.01137 9.44012L3.12483 5.39272C3.20825 5.23295 3.35109 5.11233 3.52258 5.05683C3.69407 5.00134 3.88049 5.01541 4.0417 5.09602L6.1875 6.16892L3.4375 11.4353Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M18.5625 11.5277L17.1875 13.1362L14.0252 16.2985C13.9413 16.3824 13.837 16.4432 13.7226 16.4749C13.6082 16.5066 13.4875 16.5081 13.3724 16.4793L8.39169 15.2342C8.29827 15.2108 8.2108 15.1681 8.13494 15.1088L3.4375 11.4355" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M17.1875 13.1367L13.4062 10.3867L12.3062 11.2117C11.8302 11.5687 11.2512 11.7617 10.6562 11.7617C10.0612 11.7617 9.48224 11.5687 9.00623 11.2117L8.54038 10.8623C8.46158 10.8032 8.3964 10.7279 8.34925 10.6414C8.30211 10.5549 8.27409 10.4593 8.26711 10.3611C8.26013 10.2628 8.27434 10.1642 8.30878 10.0719C8.34323 9.97965 8.3971 9.89585 8.46675 9.8262L11.8299 6.46308C11.8937 6.39924 11.9695 6.3486 12.0529 6.31405C12.1363 6.2795 12.2257 6.26172 12.316 6.26172H15.8125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M6.23688 6.1687L10.6472 4.88295C10.8047 4.83702 10.9735 4.84896 11.123 4.91661L14.0938 6.26081" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M9.625 18.2928L7.03489 17.6453C6.92979 17.619 6.83238 17.5682 6.75061 17.4972L4.8125 15.8125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                   </div>
                                   <div className="s-cnt">
                                      <h4  className="m-0">Thriving partner ecosystem</h4>
                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet posuere lorem eget est. Id vitae a pellentesque in nunc non eleme ntum nibh tellus. Facilisis ut mattis morbi enim.</p>
                                   </div>
                                </div>
                             </div>
                          </div>
                          <a className="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
                             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.125 10H16.875" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                             </svg>
                          </a>
                          <a className="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
                             <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.125 10H16.875" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#05B8FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                             </svg>
                          </a>
                       </div>
                    </div>
                    {data.allWpPage.edges[0].node.AboutUs.engineeringAtScale && <div className="col-md-5">
                       <div className="solution-right bg-white">
                          <figure className="mb-0">
                              <img alt="rl" src={data.allWpPage.edges[0].node.AboutUs.engineeringAtScale.image.sourceUrl} />
                           </figure>
                          <div className="sec-padding">
                             <h3>{data.allWpPage.edges[0].node.AboutUs.engineeringAtScale.title}</h3>
                             <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.engineeringAtScale.description }}></span>
                             <button onclick="location.href = 'single-case-study.html'; return false;" className="estimate-btn btn btn-bg btn-design text-white px-3 mb-0 btn-width ">
                                {data.allWpPage.edges[0].node.AboutUs.engineeringAtScale.buttonText}
                             </button> 
                          </div>
                       </div>
                    </div>}
                 </div>
              </div>
            </section>
         )
      }}
   />
)

export default BuildBetter;