import React from 'react';
import singlecbg from "../../assets/images/single-c-bg.png"
import { StaticQuery, graphql } from "gatsby"

const Banner = () => (
    <section className="workflow-banner">
        <div className="bn-bg quote-bg">
            <img src={singlecbg} alt="case study" />
        </div>
        <StaticQuery
         query={graphql`
            query {
                allWpPage(filter: {slug: {eq: "about-us"}}) {
                    edges {
                      node {
                        AboutUs {
                          headline {
                            description
                            title
                          }
                        }
                      }
                    }
                }
            }
         `}
         render={data => {
            return (
                data && 
                data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                data.allWpPage.edges[0].node.AboutUs &&
                data.allWpPage.edges[0].node.AboutUs.headline &&
                <div className="container">
                    <div className="row">
                        <div className="col-md-12  d-flex flex-column sectiontop-pd ">
                            <div className="quote-banner workflow mission-banner text-center case-heading my-auto ml-0 ">
                                <h3 className="">
                                    {data.allWpPage.edges[0].node.AboutUs.headline.title}
                                </h3>
                                <div dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.headline.description }}></div>
                            </div>
                        </div>
                    </div> 
                </div>
            )
         }}
      />
    </section>   
)

export default Banner;