import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import dot from "../../assets/images/dot.svg";
import h1 from "../../assets/images/h1.png";
import h2 from "../../assets/images/h2.png";
import h3 from "../../assets/images/h3.png";
import h4 from "../../assets/images/h4.png";
import h5 from "../../assets/images/h5.png";
import h6 from "../../assets/images/h6.png";
import h7 from "../../assets/images/h7.png";
import h8 from "../../assets/images/h8.png";
import h9 from "../../assets/images/h9.png";
import h10 from "../../assets/images/h10.png";
import h11 from "../../assets/images/h11.png";
import h12 from "../../assets/images/h12.png";
import c1 from "../../assets/images/c-1.png";
import c2 from "../../assets/images/c-2.png";
import c3 from "../../assets/images/c-3.png";
import c4 from "../../assets/images/c-4.png";
import c5 from "../../assets/images/c-5.png";
import c6 from "../../assets/images/c-6.png";
import c7 from "../../assets/images/c-7.png";
import c8 from "../../assets/images/c-8.png";
import c9 from "../../assets/images/c-9.png";
import c10 from "../../assets/images/c-10.png";
import c11 from "../../assets/images/c-11.png";
import c12 from "../../assets/images/c-12.png";

const Collaboration = () => (
  <section class="colaborate-homesec mt-4 py-2  py-xl-4  py-lg-2 sectiontop-pd sectionbtm-pd">
    <StaticQuery
        query={graphql`
         query {
            allWpPage(filter: {slug: {eq: "about-us"}}) {
                edges {
                  node {
                    AboutUs {
                      featuredIn {
                        partnerLogos {
                          logo {
                            uri
                            title
                            sourceUrl
                          }
                          website
                        }
                        title
                      }
                    }
                  }
                }
              }
          }
    `}
    render={data => {
        return(
            data && 
            data.allWpPage &&
            data.allWpPage.edges &&
            data.allWpPage.edges[0] &&
            data.allWpPage.edges[0].node &&
            data.allWpPage.edges[0].node.AboutUs &&
            data.allWpPage.edges[0].node.AboutUs.featuredIn &&
            <div className="container">
             <div className="d-flex justify-content-center align-items-end head-align">
                <h1 className="text-center m-0">{data.allWpPage.edges[0].node.AboutUs.featuredIn.title}</h1>
                <span className="dot-head">
                    <img src={dot} alt="dots" />
                </span>
             </div>
              <div class="colaborate-homeinner my-5 desktop-hidden">
                  <div className="colab row  d-flex justify-content-between flex-wrap p-0 border-radius24">
                     
                         {    data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos&&
                              data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos.length>0&&
                              data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos.map(
                                 prop => {
                                      return(
                                       <div class="colab-grid col-md-3 col-sm-3 py-1 px-2 p-5">
                                           <img src={prop.logo.sourceUrl} alt="colab img" />
                                        </div>
                                      )
                                 })
                            }
                     
                  </div>
              </div>
              
              <div className="colaborate-homeinner my-5 mobile-hidden">
                   <div className="colab row  d-flex justify-content-between  flex-wrap p-0 border-radius24">
                     
                         {    data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos&&
                              data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos.length>0&&
                              data.allWpPage.edges[0].node.AboutUs.featuredIn.partnerLogos.map(
                                 prop => {
                                      return(
                                       <div class="colab-grid col-md-3 col-sm-3 py-1 px-2 p-5">
                                           <img src={prop.logo.sourceUrl} alt="colab img" />
                                        </div>
                                      )
                                 })
                            }
                  </div>
              </div>                   
           </div>
          )
    }}
    />
    
  </section>
)

export default Collaboration;