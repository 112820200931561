import React from 'react';
import { StaticQuery, graphql  } from 'gatsby';

const Count = () => (
    <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "about-us"}}) {
                  edges {
                    node {
                      AboutUs {
                        teamlanceFeatures {
                            title
                            description
                            features {
                              description
                              title
                            }
                          }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
           
            return(
                data && data.allWpPage &&
                data.allWpPage.edges &&
                data.allWpPage.edges[0] &&
                data.allWpPage.edges[0].node &&
                data.allWpPage.edges[0].node.AboutUs &&
                data.allWpPage.edges[0].node.AboutUs.teamlanceFeatures &&
                <section id="counts">
                    <div className="count-graphics count-aboutsec">
                        <svg width={304} height={611} viewBox="0 0 304 611" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M53.9342 195.676L951.866 -491V-417.662V473.338C954.417 630.23 951.866 973.568 951.866 1102L53.9342 415.324C20.0431 389.054 0 348.554 0 305.5C0.36442 262.446 20.0431 221.946 53.9342 195.676Z" fill="url(#paint0_linear)" />
                            <defs>
                            <linearGradient id="paint0_linear" x1="476.5" y1={-491} x2="476.5" y2={1102} gradientUnits="userSpaceOnUse">
                                <stop stopColor="#004179" />
                                <stop offset={1} stopColor="#19B8F8" />
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="container py-4">
                      <div className="row">
                          <div className="col-md-9">
                                <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.teamlanceFeatures.description }} className="cont-peragraph mb-5">
                                    
                                </span>
                                <div className="row">
                                    {data.allWpPage.edges[0].node.AboutUs.teamlanceFeatures.features
                                      &&data.allWpPage.edges[0].node.AboutUs.teamlanceFeatures.features.length>0
                                      &&data.allWpPage.edges[0].node.AboutUs.teamlanceFeatures.features.map((data,key)=>{
                                            return(
                                                <div key={key} className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                     <div className="count-items count-p">
                                                         <h3>{data.title}</h3>
                                                         <p>{data.description}</p>
                                                     </div>
                                                </div>
                                            )
                                      })
                                     }
                                </div>
                           </div>
                        </div>
                    </div>
                 </section>
             )
         }}
     />  
)

export default Count;