import React from 'react';
import lg from "../../assets/images/lg.png";
import { StaticQuery, graphql  } from 'gatsby';

const ComeOut = () => (
  <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "about-us"}}) {
                  edges {
                    node {
                      AboutUs {
                        workWithUs {
                          image {
                            sourceUrl
                            title
                          }
                          workBlock {
                            title
                            svgIcon
                            iconColour
                            iconBgColour
                            blockUrl
                            description
                            arrowSvg
                            icon {
                              title
                              sourceUrl
                            }
                          }
                          
                        }
                        getTheLatestFromTeamlance {
                          buttonBgColour
                          buttonText
                          description
                          mailTo
                          title
                          placeholderText
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
              data && data.allWpPage &&
              data.allWpPage.edges &&
              data.allWpPage.edges[0] &&
              data.allWpPage.edges[0].node &&
              data.allWpPage.edges[0].node.AboutUs &&
              data.allWpPage.edges[0].node.AboutUs.workWithUs &&
              <section id="comeout">
                <div className="small-shape">
                  <svg width={241} height={275} fill="none">
                      <g clipPath="url(#clip0)">
                        <path d="M56.57 54.8l436.56 382.82-36.93 7.41L7.48 535.08c-78.76 17.14-251.93 50.55-316.6 63.53l255.07-521.6A71.42 71.42 0 01-4.2 38.73 71.98 71.98 0 0156.57 54.8z" fill="#F3FCFF" />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" transform="rotate(78.65 97.5 119.01)" d="M0 0h232.28v238.67H0z" />
                        </clipPath>
                      </defs>
                  </svg>
                </div>
                <div className="container-fluid px-0">
                  <div className="large-image">
                      <img src={data.allWpPage.edges[0].node.AboutUs.workWithUs.image.sourceUrl}/>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                      {data.allWpPage.edges[0].node.AboutUs.workWithUs.workBlock.length>0
                       &&data.allWpPage.edges[0].node.AboutUs.workWithUs.workBlock.map((block,key)=>{
                            return(
                              <div key={key} className="col-md-6">
                                  <div className="come-inner d-flex align-items-center ">
                                      <span style={{backgroundColor:block.iconBgColour}} dangerouslySetInnerHTML={{ __html: block.svgIcon }} className="come-icons"></span>
                                      <div>
                                        <h3 onclick="location.href = 'Careers.html'; return false;">
                                            {block.title}
                                            <span dangerouslySetInnerHTML={{ __html: block.arrowSvg }}>
                                            </span>
                                        </h3>
                                        <p>{block.description}</p>
                                      </div>
                                  </div>
                               </div>
                            )
                       })
                        
                      }
                  </div>
                  {data.allWpPage.edges[0].node.AboutUs.getTheLatestFromTeamlance && <div className="row about-subscribe d-flex justify-content-between align-items-center">
                      <div className="col-md-6 ">
                        <h2 className="m-0">{data.allWpPage.edges[0].node.AboutUs.getTheLatestFromTeamlance.title}</h2>
                        <span dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.AboutUs.getTheLatestFromTeamlance.description }}></span>
                      </div>
                      <div className="col-md-5 ">
                        <div className="input-group news-input">
                            <input type="email" className="form-control" placeholder={data.allWpPage.edges[0].node.AboutUs.getTheLatestFromTeamlance.placeholderText}/>
                            <span className="input-group-btn">
                                <button className="btn" type="submit">{data.allWpPage.edges[0].node.AboutUs.getTheLatestFromTeamlance.buttonText}</button>
                            </span>
                        </div>
                      </div>
                  </div>}
                </div>
                <div className="why-triangle why-triangle-about">
                  <svg width={489} height={212} viewBox="0 0 489 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M490 67.5004L366.13 17.6306L7.49989 116.5L490 141.5L490 67.5004Z" fill="#F4F8FF" />
                  </svg>
                </div>
             </section>
              )
         }}
      />
)

export default ComeOut;